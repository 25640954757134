import {Component} from '@angular/core';
import { TranslationService } from '@services/translation.service';

@Component({
    selector: 'app-language',
    templateUrl: './language.component.html',
    styleUrls: ['./language.component.scss']
})
export class LanguageComponent {

    currentLanguage: string;

  constructor(public translationService: TranslationService) {
    this.currentLanguage = this.translationService.currentLanguage; // Obține limba curentă
    this.translationService.language$.subscribe(lang => {
      this.currentLanguage = lang; // Actualizează limba curentă
    });
  }

  changeLanguage(lang: string) {
    this.translationService.setLanguage(lang); // Schimbă limba
  }  

}
