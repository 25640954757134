import { Component } from '@angular/core';
import { TranslationService } from '@services/translation.service';

@Component({
  selector: 'app-notificari',
  templateUrl: './notificari.component.html',
  styleUrl: './notificari.component.scss'
})
export class NotificariComponent {
  constructor(public translationService: TranslationService) { }
}
