import { Component } from '@angular/core';
import { TranslationService } from '@services/translation.service';

@Component({
  selector: 'app-acasa',
  templateUrl: './acasa.component.html',
  styleUrl: './acasa.component.scss'
})
export class AcasaComponent {
  constructor(public translationService: TranslationService) { }
}
