import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainPageComponent } from './main-page/main-page.component';
import { ContactComponent } from './pages/contact/contact.component';
import { AcasaComponent } from './pages/acasa/acasa.component';
import { TransparentaComponent } from './pages/transparenta/transparenta.component';


const routes: Routes = [
  {
    path: '', // Ruta implicită pentru modul (ex. localhost:4200/main-page)
    component: MainPageComponent,
    outlet: "outlet-portal"
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)], // Folosește forChild pentru module
  exports: [RouterModule]
})
export class MainPageRoutingModule { }
