import { Component } from '@angular/core';

@Component({
  selector: 'app-main-page-admin',
  templateUrl: './main-page-admin.component.html',
  styleUrl: './main-page-admin.component.scss'
})
export class MainPageAdminComponent {

}
