import { Component } from '@angular/core';
import { TranslationService } from '@services/translation.service';

@Component({
  selector: 'app-e-servicii',
  templateUrl: './e-servicii.component.html',
  styleUrl: './e-servicii.component.scss'
})
export class EServiciiComponent {
  constructor(public translationService: TranslationService) { }
}
