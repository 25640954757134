<div class="card card-outline card-primary">
    <div class="card-header text-center">
        <a [routerLink]="['/']" class="h1"><b>Portal</b> ANAF</a>
    </div>
    <div class="card-body">
        <h5 class="login-box-msg">Autentificare Utilizator</h5>

        <form [formGroup]="loginForm" (ngSubmit)="loginByAuth()">
            <div class="input-group mb-3">
                <input
                    formControlName="email"
                    type="email"
                    class="form-control"
                    placeholder="Email"
                    required
                />
                <div class="input-group-append">
                    <div class="input-group-text">
                        <span class="fas fa-envelope"></span>
                    </div>
                </div>
            </div>
            <div class="input-group mb-3">
                <input
                    formControlName="password"
                    type="password"
                    class="form-control"
                    placeholder="Parola"
                    required
                />
                <div class="input-group-append">
                    <div class="input-group-text">
                        <span class="fas fa-lock"></span>
                    </div>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-7">
                    <div class="icheck-primary">
                        <input type="checkbox" id="remember" />
                        <label for="remember"> Remember Me </label>
                    </div>
                </div>
                <div class="col-5">
                    <button type="submit" class="btn btn-primary" [disabled]="isAuthLoading">Autentificare</button>
                </div>
            </div>
        </form>

        <p class="mb-1">
            <a [routerLink]="['/forgot-password']">Am uitat parola</a>
        </p>
        <p class="mb-0">
            <a [routerLink]="['/register']" class="text-center">Înregistrează o nouă membră</a>
        </p>

        <h5 class="mt-5 text-center">Autentificare prin:</h5>
        <div class="social-auth-links text-center mb-3">
            <button class="btn btn-primary mb-2" (click)="loginByToken()" [disabled]="isAuthLoading">
                Autentificare prin Token
            </button>
            <button class="btn btn-secondary" (click)="loginByROeID()" [disabled]="isAuthLoading">
                <!-- <img src="assets/img/logoroei.png" alt="ROeID Login" style="width: 20px; height: 20px; margin-right: 5px; vertical-align: middle;" /> -->
                Autentificare prin ROeID
            </button>
        </div>
    </div>
    <!-- /.login-card-body -->
</div>
