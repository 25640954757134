import { Component } from '@angular/core';

@Component({
  selector: 'app-transparenta',
  templateUrl: './transparenta.component.html',
  styleUrl: './transparenta.component.scss'
})
export class TransparentaComponent {

}
