<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container">
        <button class="navbar-toggler" type="button" (click)="toggleNavbar()" data-toggle="collapse"
            data-target="#navbarNav" aria-controls="navbarNav"
            aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" style="text-align: center;" id="navbarNav">
            <ul class="navbar-nav mx-auto">
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" [routerLink]="'/'"
                        id="navbarDropdown" role="button"
                        aria-expanded="false">
                        {{ translationService.translate('menu.acasa') }}
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li>
                            <a class="dropdown-item" [routerLink]="'/anaf'"
                                style="cursor: pointer;">Despre ANAF</a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="'/transparenta'"
                        routerLinkActive="active">{{ translationService.translate('menu.transparenta') }}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="'/legislatie'"
                        style="cursor: pointer;"
                        routerLinkActive="active">{{ translationService.translate('menu.legislatie') }}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="'/servicii'"
                        style="cursor: pointer;"
                        routerLinkActive="active">{{ translationService.translate('menu.servicii-online') }}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="'/contact'"
                        style="cursor: pointer;"
                        routerLinkActive="active">{{ translationService.translate('menu.contact') }}</a>
                </li>
                
                <!-- Butonul SPV -->
                <li class="nav-item">
                    <a class="btn btn-primary" [routerLink]="'/spv'" style="margin-left: 10px;">
                        <b>SPV</b>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</nav>

