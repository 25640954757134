import { Component } from '@angular/core';
import { TranslationService } from '@services/translation.service';

@Component({
  selector: 'app-solicitari',
  templateUrl: './solicitari.component.html',
  styleUrl: './solicitari.component.scss'
})
export class SolicitariComponent {
  constructor(public translationService: TranslationService) { }
}
