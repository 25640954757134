<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1 class="m-0">{{ translationService.translate("spatiu-de-lucru")
          }}</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a [routerLink]="['/spv']">SPV</a></li>
          <li class="breadcrumb-item active">Programari</li>
        </ol>
      </div>
    </div>
  </div>
</div>
<section class="content">
    <div class="container-fluid">
        <!-- <div class="container mt-5">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Completează detaliile programării</h3>
              </div>
              <div class="card-body">
                <form (ngSubmit)="onSubmit()">
          
                  <div class="form-group row mb-3">
                    <div class="col-md-6">
                      <label for="cuiOrCnp" class="form-label">CUI / CNP / NIF</label>
                      <input type="text" id="cuiOrCnp" 
                             [(ngModel)]="programare.cuiOrCnp" 
                             name="cuiOrCnp" class="form-control" 
                             placeholder="Introdu CUI/CNP/NIF" required>
                    </div>
                    <div class="col-md-6">
                      <label for="numeComplet" class="form-label">Nume Complet</label>
                      <input type="text" id="numeComplet" 
                             [(ngModel)]="programare.numeComplet" 
                             name="numeComplet" class="form-control" 
                             placeholder="Introdu numele complet" required>
                    </div>
                  </div>
                  
                  <div class="form-group row mb-3">
                    <div class="col-md-6">
                      <label for="cuiOrCnp" class="form-label">CUI / CNP / NIF</label>
                      <input type="text" id="cuiOrCnp" 
                             [(ngModel)]="programare.cuiOrCnp" 
                             name="cuiOrCnp" class="form-control" 
                             placeholder="Introdu CUI/CNP/NIF" required>
                    </div>
                    <div class="col-md-6">
                      <label for="numeComplet" class="form-label">Nume Complet</label>
                      <input type="text" id="numeComplet" 
                             [(ngModel)]="programare.numeComplet" 
                             name="numeComplet" class="form-control" 
                             placeholder="Introdu numele complet" required>
                    </div>
                  </div>
                  <div class="form-group mb-3">
                    <label for="denumireSocietate" class="form-label">Denumire Societate</label>
                    <input type="text" id="denumireSocietate" 
                           [(ngModel)]="programare.denumireSocietate" 
                           name="denumireSocietate" class="form-control" 
                           placeholder="Introdu denumirea societății" required>
                  </div>
          
                  <div class="form-group row mb-3">
                    <div class="col-md-6">
                      <label for="email" class="form-label">Email</label>
                      <input type="email" id="email" 
                             [(ngModel)]="programare.email" 
                             name="email" class="form-control" 
                             placeholder="Introdu email-ul" required>
                    </div>
                    <div class="col-md-6">
                      <label for="numarTelefon" class="form-label">Număr de Telefon</label>
                      <input type="text" id="numarTelefon" 
                             [(ngModel)]="programare.numarTelefon" 
                             name="numarTelefon" class="form-control" 
                             placeholder="Introdu numărul de telefon" required>
                    </div>
                  </div>
          
                  <div class="form-group row mb-3">
                    <div class="col-md-6">
                        <label for="agentieFiscala" class="form-label">Agenția Fiscală</label>
                        <select class="form-control form-select required">
                            <option value="">Selectează agenția fiscală</option>
                            <option *ngFor="let agentie of agentii" [value]="agentie">{{ agentie }}</option>
                          </select>
                      </div>
                    <div class="col-md-6">
                      <label for="dataSiOra" class="form-label">Data și Ora Programării</label>
                      <input type="datetime-local" id="dataSiOra" 
                             [(ngModel)]="programare.dataSiOra" 
                             name="dataSiOra" class="form-control" required>
                    </div>
                  </div>
          
                  <button type="submit" class="btn btn-primary">Trimite</button>
                </form>
              </div>
            </div>
          </div> -->
          <div class="container mt-5">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">{{ translationService.translate('form-title') }}</h3>
              </div>
              <div class="card-body">
                <form (ngSubmit)="onSubmit()">
                  <div class="row mb-3">
                    <div class="col-md-6">
                      <label for="cuiOrCnp" class="form-label">{{ translationService.translate('cuiOrCnp') }}</label>
                      <input type="text" id="cuiOrCnp" 
                             [(ngModel)]="programare.cuiOrCnp" 
                             name="cuiOrCnp" class="form-control" 
                             placeholder="{{ translationService.translate('placeholder-cuiOrCnp') }}" required>
                    </div>
                    <div class="col-md-6">
                        <label for="numeComplet" class="form-label">{{ translationService.translate('name') }}</label>
                        <input type="text" id="numeComplet" 
                               [(ngModel)]="programare.numeComplet" 
                               name="numeComplet" class="form-control" 
                               placeholder="{{ translationService.translate('placeholder-name') }}" required>
                      </div>
                    </div>
            
                    <div class="mb-3">
                      <label for="denumireSocietate" class="form-label">{{ translationService.translate('companyName') }}</label>
                      <input type="text" id="denumireSocietate" 
                             [(ngModel)]="programare.denumireSocietate" 
                             name="denumireSocietate" class="form-control" 
                             placeholder="{{ translationService.translate('placeholder-companyName') }}" required>
                    </div>
            
                    <div class="row mb-3">
                      <div class="col-md-6">
                        <label for="email" class="form-label">{{ translationService.translate('email') }}</label>
                        <input type="email" id="email" 
                               [(ngModel)]="programare.email" 
                               name="email" class="form-control" 
                               placeholder="{{ translationService.translate('placeholder-email') }}" required>
                      </div>
                      <div class="col-md-6">
                        <label for="numarTelefon" class="form-label">{{ translationService.translate('phoneNumber') }}</label>
                        <input type="text" id="numarTelefon" 
                               [(ngModel)]="programare.numarTelefon" 
                               name="numarTelefon" class="form-control" 
                               placeholder="{{ translationService.translate('placeholder-phoneNumber') }}" required>
                      </div>
                    </div>
            
                    <div class="row mb-3">
                        <div class="col-md-6">
                            <label for="agentieFiscala" class="form-label">{{ translationService.translate('taxAgency') }}</label>
                            <select class="form-control form-select required">
                                <option value="">{{ translationService.translate('placeholder-selectTaxAgency')}}</option>
                                <option *ngFor="let agentie of agentii" [value]="agentie">{{ agentie }}</option>
                              </select>
                          </div>
                      <div class="col-md-6">
                        <label for="dataSiOra" class="form-label">{{ translationService.translate('appointmentDate') }}</label>
                        <input type="datetime-local" id="dataSiOra" 
                               [(ngModel)]="programare.dataSiOra" 
                               name="dataSiOra" class="form-control" required>
                      </div>
                    </div>
            
                    <button type="submit" class="btn btn-primary">{{ translationService.translate('submit') }}</button>
                  </form>
                </div>
              </div>
            </div>          
    </div>
</section>
