
<div class="anaf-container">
  <div class="menu">
    <ul>
      <li *ngFor="let item of menuItems" (click)="selectMenuItem(item)"
        style="cursor: pointer;">
        {{ item.title }}
      </li>
    </ul>
  </div>
  <div class="content">
    <h2>{{ selectedMenuItem.title }}</h2>
    <div class="card">
      <p>{{ selectedMenuItem.content }}</p>
    </div>
  </div>
</div>

