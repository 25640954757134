
<div class="container">
    <div>
        <h2>Acte Legislative</h2>

        <input type="text" [(ngModel)]="searchText" placeholder="Caută acte..."
            class="form-control mb-3" />

        <table class="table table-striped">
            <thead>
                <tr>
                    <th (click)="sort('titlu')" style="cursor: pointer;">
                        Titlu
                        <span *ngIf="sortBy === 'titlu'">
                            <i
                                [ngClass]="sortOrder ? 'fa fa-sort-up' : 'fa fa-sort-down'"></i>
                        </span>
                    </th>
                    <th (click)="sort('data')" style="cursor: pointer;">
                        Data
                        <span *ngIf="sortBy === 'data'">
                            <i
                                [ngClass]="sortOrder ? 'fa fa-sort-up' : 'fa fa-sort-down'"></i>
                        </span>
                    </th>
                    <th>Descriere</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    *ngFor="let act of acts | filter: searchText | orderBy: sortBy: sortOrder | paginate: { itemsPerPage: itemsPerPage, currentPage: p }">
                    <td>{{ act.titlu }}</td>
                    <td>{{ act.data }}</td>
                    <td>{{ act.descriere }}</td>
                </tr>
            </tbody>
        </table>

        <pagination-controls (pageChange)="p = $event">
            <!-- <ng-template let-controls="controls">
                <button class="btn btn-default" (click)="controls.previous()"
                    [disabled]="!controls.hasPrevious()">
                    {{ trans.translate('previous') }}
               
                </button>
                <button class="btn btn-default" (click)="controls.next()"
                    [disabled]="!controls.hasNext()">
                    {{ trans.translate('next') }}
             
                </button>
            </ng-template> -->
        </pagination-controls>
        

        <div *ngIf="acts.length === 0">
            <p>Nu au fost găsite acte legislative pentru căutarea ta</p>
        </div>
    </div>
</div>
