<div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1 class="m-0">{{ translationService.translate("dosar-electronic")
            }}</h1>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a [routerLink]="['/spv']">SPV</a></li>
            <li class="breadcrumb-item active">Dosar electronic</li>
          </ol>
        </div>
      </div>
    </div>
  </div>