<main-page-carousel></main-page-carousel>
<main class="container my-5">
    <div class="content">
        <div class="row">
            <!-- Card 1 -->
            <div class="col-md-6">
                <div class="card mb-6">
                    <div class="card-body">
                        <h5 class="card-title"> {{ translationService.translate("description ANAF")}}:</h5>
                        <p class="card-text">{{ translationService.translate("description ANAF full")}}</p>
                        <p class="text-primary" style="cursor: pointer;">Citește
                            mai mult</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card mb-6">
                    <div class="card-body">
                        <h5 class="card-title">Prin timp</h5>
                        <p class="card-text">Agenția Națională de Administrare
                            Fiscală (ANAF) din România a fost înființată în anul
                            2003, având rolul de a centraliza și eficientiza
                            administrarea fiscală. ANAF se ocupă cu colectarea
                            impozitelor și contribuțiilor sociale, asigurând
                            respectarea legislației fiscale și oferind asistență
                            contribuabililor. De-a lungul anilor, agenția a
                            adoptat măsuri de modernizare și digitalizare,
                            facilitând interacțiunea cu contribuabilii prin
                            servicii online. De asemenea, ANAF desfășoară
                            activități de control pentru combaterea evaziunii
                            fiscale, contribuind astfel la stabilitatea
                            financiară a statului și la dezvoltarea economică a
                            României.</p>
                        <p class="text-primary" style="cursor: pointer;">Citește
                            mai mult</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>