<footer class="footer text-white text-center py-3">
    <div class="container">
        <div class="row">
            <div class="col-md text-left"> <!-- Aliniere la stânga -->
                <!-- Logo și linkuri rețele sociale -->
                <!-- <img src="../assets/img/apolodor_logo_full.png" alt="Logo" style="width: 50%; margin-bottom: 10px;">  -->
                <h6>Urmărește-ne</h6>
                <ul class="list-unstyled">
                    <li><a href="#" class="text-white"
                            aria-label="Facebook"><i
                                class="fab fa-facebook"></i>
                            Facebook</a></li>
                    <li><a href="#" class="text-white"
                            aria-label="Twitter"><i
                                class="fab fa-twitter"></i>
                            Twitter</a></li>
                    <li><a href="#" class="text-white"
                            aria-label="LinkedIn"><i
                                class="fab fa-linkedin"></i>
                            LinkedIn</a></li>
                    <li><a href="#" class="text-white"
                            aria-label="Instagram"><i
                                class="fab fa-instagram"></i>
                            Instagram</a></li>
                    <li><a href="#" class="text-white"
                            aria-label="YouTube"><i
                                class="fab fa-youtube"></i>
                            YouTube</a></li>
                </ul>
            </div>
            <div class="col-md text-left">
                <!-- Alinierea la stânga a hărții și textului coloanei -->
                <h6>Directii Regionale</h6> <!-- Titlul coloanei -->
                <img src="../assets/img/harta-romania.png"
                    alt="Harta României"
                    style="width: 100%; height: auto; margin-bottom: 10px;">
                <!-- Harta interactivă -->
            </div>
            <div class="col-md text-left" style="flex-basis: 400px;">
                <ul class="list-unstyled">
                    <li><a href="#" class="text-white">Directia Generala
                            Antifrauda Fiscala</a></li>
                    <li><a href="#" class="text-white">Directia Generala
                            a Vamilor</a></li>
                    <li><a href="#" class="text-white">Directia Generala
                            de Integritate</a></li>
                    <li><a href="#" class="text-white">Directia Generala
                            Control Venituri Persoane Fizice</a></li>
                    <li><a href="#" class="text-white">Directia Generala
                            Coordonare Control Fiscal</a></li>
                    <li><a href="#" class="text-white">Directia Generala
                            de Managementul al Riscului</a></li>
                    <li><a href="#" class="text-white">Directia Generala
                            de Administrare a Marilor
                            Contribuabili</a></li>
                    <li><a href="#" class="text-white">Directia Generala
                            a Finantelor Publice Bucuresti</a></li>
                    <li><a href="#" class="text-white">Directia Generala
                            Regionale ale Finantelor Publice</a></li>
                </ul>
            </div>
            <div class="col-md text-left" style="flex-basis: 100px;">
                <h6>Link-uri Utile</h6>
                <ul class="list-unstyled">
                    <li><a href="#" class="text-white">Presidentia
                            Romaniei</a></li>
                    <li><a href="#" class="text-white">Guvernul
                            Romaniei</a></li>
                    <li><a href="#" class="text-white">Senatul
                            Romaniei</a></li>
                    <li><a href="#" class="text-white">Camera
                            Deputatilor</a></li>
                    <li><a href="#" class="text-white">Banca Nationala a
                            Romaniei</a></li>
                    <li><a href="#" class="text-white">Ministerul
                            Finantelor</a></li>
                </ul>
            </div>
            <div class="col-md text-left">
                <h6>Resurse</h6>
                <ul class="list-unstyled">
                    <li><a href="#" class="text-white">Blog</a></li>
                    <li><a href="#"
                            class="text-white">Documentație</a></li>
                    <li><a href="#" class="text-white">FAQ</a></li>
                </ul>
            </div>
        </div>
    </div>
    <p class="mt-3">© 2024 <a href="#" style="color: aqua;">RA
            RASIROM</a> Toate drepturile rezervate.</p>
</footer>