import { Component } from '@angular/core';

interface MenuItem {
  title: string;
  content: string;
}

@Component({
  selector: 'app-anaf',
  templateUrl: './anaf.component.html',
  styleUrls: ['./anaf.component.scss']
})
export class AnafComponent {
  menuItems: MenuItem[] = [
    {
      title: 'Despre ANAF',
      content: 'ANAF este instituția responsabilă cu gestionarea sistemului fiscal în România. Aici te poți informa despre rolul și funcțiile ANAF.'
    },
    {
      title: 'Conducere',
      content: 'Conducerea ANAF este formată din experți în domeniul fiscalității care coordonează activitățile instituției.'
    },
    {
      title: 'Organizare',
      content: 'ANAF este organizată pe baza unor structuri care asigură o colectare eficientă a impozitelor și a altor taxe.'
    },
    {
      title: 'Strategii ANAF',
      content: 'ANAF implementează strategii menite să modernizeze administrația fiscală și să faciliteze conformarea fiscală.'
    }
  ];

  // Variabila care va păstra itemul de meniu selectat
  selectedMenuItem: MenuItem = this.menuItems[0]; // Setăm ca implicit primul meniu

  // Funcția pentru a schimba cardul în funcție de selecția din meniu
  selectMenuItem(item: MenuItem) {
    this.selectedMenuItem = item;
  }
}
