import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainPageComponent } from './main-page/main-page.component';
import { MainPageRoutingModule } from './main-page-routing.module';
import { MenuComponent } from './menu/menu.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { CarouselComponent } from './carousel/carousel.component';
import { AnafComponent } from './pages/anaf/anaf.component';
import { LegislatieComponent } from './pages/legislatie/legislatie.component';
import { FormsModule } from '@angular/forms';
import { FilterPipe } from '@/filter.pipe';
import { OrderByPipe } from '@/order-by.pipe';
import { NgxPaginationModule } from 'ngx-pagination';
import { TransparentaComponent } from './pages/transparenta/transparenta.component';
import { ServiciiComponent } from './pages/servicii/servicii.component';
import { ContactComponent } from './pages/contact/contact.component';
import { AcasaComponent } from './pages/acasa/acasa.component';



@NgModule({
  declarations: [
    MainPageComponent,
    MenuComponent,
    HeaderComponent,
    FooterComponent,
    CarouselComponent,
    AnafComponent,
    LegislatieComponent,
    FilterPipe,
    OrderByPipe,
    TransparentaComponent,
    ServiciiComponent,
    ContactComponent,
    AcasaComponent
  ],
  imports: [
    CommonModule,
    MainPageRoutingModule,
    FormsModule,
    NgxPaginationModule
  ]
})
export class MainPageModule { }
