import { Component } from '@angular/core';
import { AppService } from '@services/app.service';
import { TranslationService } from '@services/translation.service';

@Component({
  selector: 'main-page-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  currentLanguage: string;
  isUserConnected: boolean = false;

  constructor(
    private authService: AppService,
    public translationService: TranslationService) { }

  ngOnInit(): void {

      this.translationService.language$.subscribe(lang => {
      this.currentLanguage = lang;
    });

    // Setează limba implicită
    this.currentLanguage = this.translationService.currentLanguage;
    this.isUserConnected = this.authService.isConnected();
  }

  // Schimbă limba
  changeLanguage(lang: string) {
    this.translationService.setLanguage(lang);
  }

  // Obține traducerea
  translate(key: string): string {
    return this.translationService.translate(key);
  }

  isLanguageActive(lang: string): boolean {
    return this.currentLanguage === lang; // Verifică dacă limba dată este activă
  }

  logout() {
    this.authService.logout();
  }

  activateSearch() {
    const searchInput = document.querySelector('.search-input') as HTMLInputElement;
    if (searchInput) {
      searchInput.focus(); // Focalizează input-ul de căutare
    }
  }
  
}



