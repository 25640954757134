import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

  transform(array: any[], field: string, ascending: boolean = true): any[] {
    if (!Array.isArray(array) || !field) return array;

    const sortedArray = array.sort((a, b) => {
      const aField = a[field] ? a[field] : ''; // Verifica dacă există câmpul
      const bField = b[field] ? b[field] : '';

      if (ascending) {
        return aField < bField ? -1 : 1; // Ascendent
      } else {
        return aField > bField ? -1 : 1; // Descendent
      }
    });

    return sortedArray;
  }
}
