import { Component } from '@angular/core';

interface Notificare {
  titlu: string;
  descriere: string;
}

@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  styleUrl: './inbox.component.scss'
})
export class InboxComponent {
  notificari = {
    mesaje: [
      { titlu: 'Mesaj de la Ion', descriere: 'Salut, cum te simți?' },
      { titlu: 'Mesaj de la Maria', descriere: 'Reuniunea este programată pentru mâine.' }
    ],
    alerte: [
      { titlu: 'Alertă de securitate', descriere: 'Contul tău a fost accesat dintr-o locație necunoscută.' },
      { titlu: 'Actualizare de sistem', descriere: 'Sistemul dvs. va fi actualizat în 10 minute.' }
    ],
    recipise: [
      { titlu: 'Recipisa D202', descriere: 'Vânzările din această lună au crescut cu 15%.' },
      { titlu: 'Recipisa D900', descriere: 'Campania de marketing a avut rezultate excelente.' }
    ],
    alte: [
      { titlu: 'Reminder', descriere: 'Nu uita să verifici emailurile.' },
      { titlu: 'Meeting', descriere: 'Întâlnirea cu echipa este programată pentru vineri.' }
    ]
  };

  cardStates = {
    mesajeOpen: true,
    alerteOpen: true,
    rapoarteOpen: true,
    alteOpen: true
  };

  toggleCard(card: string): void {
    this.cardStates[card] = !this.cardStates[card];
  }
}
