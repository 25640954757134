declare var bootstrap: any;
import { Component, OnInit, AfterViewInit, ElementRef } from '@angular/core';

interface Slide {
  image: string;
  title: string;
  description: string;
}

@Component({
  selector: 'main-page-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
// export class CarouselComponent implements OnInit, AfterViewInit {
export class CarouselComponent implements OnInit {
  constructor(private el: ElementRef) { }
  slides: Slide[] = [
    {
      image: 'https://via.placeholder.com/800x400?text=Slide+1',
      title: 'ANAF lansează programul de conformare voluntară',
      description: 'ANAF a anunțat lansarea unui nou program destinat contribuabililor care doresc să își regleze situația fiscală. Acesta va oferi asistență și consiliere pentru cei care doresc să își conformeze declarațiile fiscale.'
    },
    {
      image: 'https://via.placeholder.com/800x400?text=Slide+2',
      title: 'Control fiscal la marii contribuabili',
      description: 'ANAF a început o serie de controale fiscale pentru marii contribuabili, având ca scop verificarea conformării cu legislația fiscală. Autoritățile încurajează contribuabilii să se prezinte și să colaboreze în cadrul acestui proces.'
    },
    {
      image: 'https://via.placeholder.com/800x400?text=Slide+3',
      title: 'Modificări legislative privind TVA',
      description: 'Recent, au fost adoptate modificări importante în legislația națională privind TVA-ul. ANAF informează contribuabilii despre noile reglementări și impactul acestora asupra obligațiilor fiscale.'
    }
  ];

  currentIndex: number = 0;


  ngOnInit(): void {
    this.startAutoSlide(); // Inițializare automată a slide-ului
  }

  startAutoSlide() {
    setInterval(() => {
      this.nextSlide();
    }, 5000); // Schimbă slide-ul la fiecare 3 secunde
  }

  nextSlide() {
    this.currentIndex = (this.currentIndex + 1) % this.slides.length; // Mergi la următorul slide
  }

  prevSlide() {
    this.currentIndex = (this.currentIndex - 1 + this.slides.length) % this.slides.length; // Mergi la slide-ul anterior
  }
  // ngOnInit(): void { }


  // ngAfterViewInit(): void {
  //   const carouselElement = this.el.nativeElement.querySelector('#carouselExampleIndicators');
  //   // Inițializează caruselul din Bootstrap
  //   if (carouselElement) {
  //     const carousel = new bootstrap.Carousel(carouselElement, {
  //       interval: 2000,
  //       ride: 'carousel'
  //     });
  //   }
  // }
}
