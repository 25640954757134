import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainPageAdminComponent } from './main-page-admin/main-page-admin.component';
import { AuthGuard } from '@guards/auth.guard';

const routes: Routes = [
  {
    path: 'admin', // Ruta implicită pentru modul (ex. localhost:4200/main-page)
    component: MainPageAdminComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MainPageAdminRoutingModule { }
