import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  public currentLanguage = 'ro'; // Limba implicită
  private translations: any = {}; // Obiect pentru a stoca traducerile

  // Un BehaviorSubject pentru a urmări limba curentă
  private languageSubject = new BehaviorSubject<string>(this.currentLanguage);
  language$ = this.languageSubject.asObservable();

  constructor(private http: HttpClient) {
    this.loadTranslations();
  }

  // Încărcați traducerile dintr-un fișier JSON
  private loadTranslations() {
    this.http.get('/assets/translations.json').subscribe((data: any) => {
      this.translations = data;
    });
  }

  // Schimbați limba
  setLanguage(lang: string) {
    this.currentLanguage = lang;
    this.languageSubject.next(lang); // Emită noua limbă
  }

  // Obțineți traducerea pentru o cheie
  translate(key: string): string {
    const keys = key.split('.'); // Separă cheia pe punct
    let translation = this.translations[this.currentLanguage];

    // Accesează subcheile dacă există
    for (const k of keys) {
        if (translation && translation[k]) {
            translation = translation[k];
        } else {
            return key; // Returnează cheia originală dacă nu există traducerea
        }
    }
    return translation || key; // Returnează traducerea sau cheia originală
}
}
