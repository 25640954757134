import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MainPageAdminRoutingModule } from './main-page-admin-routing.module';
import { MainPageAdminComponent } from './main-page-admin/main-page-admin.component';


@NgModule({
  declarations: [
    MainPageAdminComponent
  ],
  imports: [
    CommonModule,
    MainPageAdminRoutingModule
  ]
})
export class MainPageAdminModule { }
