import {Component} from '@angular/core';
import { TranslationService } from '@services/translation.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {

    userId: number;
    userName: string;

    constructor(
        public translationService: TranslationService,
        private route: ActivatedRoute) { }

        ngOnInit(): void {
            // Preia parametrul "id" din ruta
            this.route.params.subscribe(params => {
              this.userId = params['id']; // accesează parametrul
              if(this.userId == 1){
                this.userName = 'ABC SRL';
              }
              if(this.userId == 2){
                this.userName = 'XYZ SA';
              }
              if(this.userId == undefined || (this.userId != 1 && this.userId != 2)){
                this.userName = 'TOATE';
              }
              console.log('User ID:', this.userId);
            });
          }
}
