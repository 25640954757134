<div class="float-right d-none d-sm-block"><b>Version</b> {{ appVersion }}</div>
<strong>
    <span>Copyright &copy; {{ currentYear }}</span>
    <a
        href="https://rasirom.ro/"
        target="_blank"
        rel="noopener noreferrer"
        style="margin: 0"
    >
    rasirom.ro</a
    >
    <span>.</span>
</strong>
<span> RA RASIROM - Spatiu Virtual Privat</span>
