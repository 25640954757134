<pf-dropdown [hideArrow]="true" [class.show]="isDropdownVisible">
    <div slot="head" (click)="toggleDropdown()">
        <a class="nav-link">
            <i class="far fa-bell"></i>
            <span class="badge badge-warning navbar-badge">8</span>
        </a>
    </div>
    <div slot="body" *ngIf="isDropdownVisible">
        <span class="dropdown-item dropdown-header">8 notificari</span>
        <div class="dropdown-divider"></div>
        <a (click)="onClick()" class="dropdown-item">
            <i class="fas fa-envelope mr-2"></i> 2 mesaje noi
            <span class="float-right text-muted text-sm">3 mins</span>
        </a>
        <div class="dropdown-divider"></div>
        <a (click)="onClick()" class="dropdown-item">
            <i class="fas fa-users mr-2"></i> 2 alerte
            <span class="float-right text-muted text-sm">12 hours</span>
        </a>
        <div class="dropdown-divider"></div>
        <a (click)="onClick()" class="dropdown-item">
            <i class="fas fa-file mr-2"></i> 2 rapoarte noi
            <span class="float-right text-muted text-sm">2 days</span>
        </a>
        <div class="dropdown-divider"></div>
        <a (click)="onClick()" class="dropdown-item">
            <i class="fas fa-folder mr-2"></i> 2 recipise
            <span class="float-right text-muted text-sm">2 days</span>
        </a>
        <div class="dropdown-divider"></div>
        <a (click)="onClick()" class="dropdown-item dropdown-footer"
            >See All Notifications</a
        >
    </div>
</pf-dropdown>

<!-- <ng-template #notification>
    <a href="#" class="dropdown-item">
        <i class="fas fa-envelope mr-2"></i> 4 new messages
        <span class="float-right text-muted text-sm">3 mins</span>
    </a>
    <div class="dropdown-divider"></div>
</ng-template> -->
