import { Component, OnInit } from '@angular/core';
import * as L from 'leaflet';

interface ContactForm {
  name: string;
  email: string;
  message: string;
}

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactForm: ContactForm = {
    name: '',
    email: '',
    message: ''
  };

  ngOnInit(): void {
    this.initMap();
  }

  initMap(): void {
    const map = L.map('map').setView([44.4268, 26.1025], 13); // Coord. pentru București

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '© OpenStreetMap'
    }).addTo(map);

    // Adaugă un marker pe hartă
    L.marker([44.4268, 26.1025]).addTo(map)
      .bindPopup('Locația noastră!')
      .openPopup();
  }

  onSubmit() {
    console.log('Form submitted!', this.contactForm);
    // Aici poți adăuga logica pentru a trimite datele formularului la un server
  }
}
