<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1 class="m-0">{{ translationService.translate("spatiu-de-lucru") }} - {{this.userName}}</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a [routerLink]="['/spv']">SPV</a></li>
          <li class="breadcrumb-item active">{{ translationService.translate("spatiu-de-lucru") }} SPV</li>
        </ol>
      </div>
    </div>
  </div>
</div>

<section class="content">
  <div class="container-fluid">
    <div class="container mt-5">
      <div class="row">
        <!-- Card 1 -->
        <div class="col-md-4 mb-4">
          <a [routerLink]="['/spv/solicitari']"> <!-- Adaugă routerLink pentru redirecționare -->
            <div class="card border-primary h-100"> <!-- H-100 pentru a menține o înălțime uniformă -->
              <div class="card-header">
                <span style="color: #024476;">Solicitari</span>
              </div>
              <div class="card-body d-flex">
                <img src="https://via.placeholder.com/100" alt="Asistență" class="mr-3" style="width: 100px;">
                <p class="card-text">Informații, Obligații de plată, Eliberări documente și alte solicitări</p>
              </div>
            </div>
          </a>
        </div>

        <!-- Card 2 -->
        <div class="col-md-4 mb-4">
          <a [routerLink]="['/spv/dosar-electronic']"> <!-- Adaugă routerLink pentru redirecționare -->
            <div class="card border-success h-100">
              <div class="card-header">
                <span style="color: #024476;">Dosar electronic</span>
              </div>
              <div class="card-body d-flex">
                <img src="https://via.placeholder.com/100" alt="Asistență" class="mr-3" style="width: 100px;">
                <p class="card-text">Depunere declarații fiscale, Calendar fiscal, Recipise, Arhivă declarații fiscale</p>
              </div>
            </div>
          </a>
        </div>

        <!-- Card 3 -->
        <div class="col-md-4 mb-4">
          <a [routerLink]="['/spv/plati']"> <!-- Adaugă routerLink pentru redirecționare -->
            <div class="card border-danger h-100">
              <div class="card-header">
                <span style="color: #024476;">Plati creante</span>
              </div>
              <div class="card-body d-flex">
                <img src="https://via.placeholder.com/100" alt="Asistență" class="mr-3" style="width: 100px;">
                <p class="card-text">Plătește, Eșalonează, Consultă.</p>
              </div>
            </div>
          </a>
        </div>
      </div>

      <div class="row">
        <!-- Card 4 -->
        <div class="col-md-4 mb-4">
          <a [routerLink]="['/spv/notificari']"> <!-- Adaugă routerLink pentru redirecționare -->
            <div class="card border-info h-100">
              <div class="card-header">
                <span style="color: #024476;">Notificari</span>
              </div>
              <div class="card-body d-flex">
                <img src="https://via.placeholder.com/100" alt="Asistență" class="mr-3" style="width: 100px;">
                <p class="card-text">Notificări emise de ANAF</p>
              </div>
            </div>
          </a>
        </div>

        <!-- Card 5
        <!-- Card 5 -->
        <div class="col-md-4 mb-4">
          <a [routerLink]="['/spv/e-servicii']"> <!-- Adaugă routerLink pentru redirecționare -->
            <div class="card border-warning h-100">
              <div class="card-header">
                <span style="color: #024476;">E-servicii</span>
              </div>
              <div class="card-body d-flex">
                <img src="https://via.placeholder.com/100" alt="Asistență" class="mr-3" style="width: 100px;">
                <p class="card-text">Ro e-factura, RO e-transport, E-TVA</p>
              </div>
            </div>
          </a>
        </div>

        <!-- Card 6 -->
        <div class="col-md-4 mb-4">
          <a [routerLink]="['/spv/programari']"> <!-- Adaugă routerLink pentru redirecționare -->
            <div class="card border-light h-100">
              <div class="card-header">
                <span style="color: #024476;">Asistență și programări online</span>
              </div>
              <div class="card-body d-flex">
                <img src="https://via.placeholder.com/100" alt="Asistență" class="mr-3" style="width: 100px;">
                <p class="card-text">Programează o întâlnire online cu inspectorul tău.</p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
