import { Component } from '@angular/core';
import { AppService } from '@services/app.service';
import { TranslationService } from '@services/translation.service';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrl: './main-page.component.scss'
})
export class MainPageComponent {

  constructor(public translationService: TranslationService) { }

  ngOnInit(): void {
  }

}
