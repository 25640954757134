import { Component } from '@angular/core';
import { TranslationService } from '@services/translation.service';

@Component({
  selector: 'app-plati',
  templateUrl: './plati.component.html',
  styleUrl: './plati.component.scss'
})
export class PlatiComponent {
  constructor(public translationService: TranslationService) { }

}
