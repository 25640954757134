<div class="container my-4"
  style="background-color: #f8f9fa; padding: 20px; border-radius: 5px;">
  <!-- <div id="newsCarousel" class="carousel slide my-4"
        data-ride="carousel">
        <div class="carousel-inner">
            <div class="carousel-item active">
                <div class="row">
                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title">ANAF lansează programul
                                    de conformare voluntară</h5>
                                <p class="card-text">ANAF a anunțat lansarea
                                    unui nou program destinat contribuabililor
                                    care doresc să își regleze situația fiscală.
                                    Acesta va oferi asistență și consiliere
                                    pentru cei care doresc să își conformeze
                                    declarațiile fiscale.</p>
                                <p class="text-primary"
                                    style="cursor: pointer;">Citește mai
                                    mult</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title">Control fiscal la marii contribuabili</h5>
                                <p class="card-text">ANAF a început o serie de
                                    controale fiscale pentru marii
                                    contribuabili, având ca scop verificarea
                                    conformării cu legislația fiscală.
                                    Autoritățile încurajează contribuabilii să
                                    se prezinte și să colaboreze în cadrul
                                    acestui proces.</p>
                                <p class="text-primary"
                                    style="cursor: pointer;">Citește mai
                                    mult</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title">Modificări legislative
                                    privind TVA</h5>
                                <p class="card-text">Recent, au fost adoptate
                                    modificări importante în legislația
                                    națională privind TVA-ul. ANAF informează
                                    contribuabilii despre noile reglementări și
                                    impactul acestora asupra obligațiilor
                                    fiscale.</p>
                                <p class="text-primary"
                                    style="cursor: pointer;">Citește mai
                                    mult</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-item">
                <div class="row">
                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title">Campanie de informare
                                    privind deducerea cheltuielilor</h5>
                                <p class="card-text">ANAF derulează o campanie
                                    de informare pentru contribuabili,
                                    accentuând importanța cunoașterii
                                    deducerilor posibile la calcularea
                                    impozitelor. Cei interesați pot accesa
                                    diverse resurse disponibile pe site-ul
                                    oficial al ANAF.</p>
                                <p class="text-primary"
                                    style="cursor: pointer;">Citește mai
                                    mult</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title">Termenele limită pentru
                                    depunerea declarațiilor fiscale</h5>
                                <p class="card-text">ANAF reamintește
                                    contribuabililor că termenul limită pentru
                                    depunerea declarațiilor fiscale pentru anul
                                    în curs se apropie. Este important ca toți
                                    contribuabilii să verifice datele limită
                                    pentru a evita penalizările.</p>
                                <p class="text-primary"
                                    style="cursor: pointer;">Citește mai
                                    mult</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a class="carousel-control-prev" href="#newsCarousel"
            role="button" data-slide="prev">
            <span class="carousel-control-prev-icon"
                aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#newsCarousel"
            role="button" data-slide="next">
            <span class="carousel-control-next-icon"
                aria-hidden="true"></span>
            <span class="sr-only">Next</span>
        </a>
    </div> -->

  <!-- <div id="carouselExampleIndicators" class="carousel slide"
    data-ride="carousel">
    <ol class="carousel-indicators">
      <li data-target="#carouselExampleIndicators" data-slide-to="0"
        class="active"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
    </ol>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <div class="row">
          <div class="col-md-6">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">ANAF lansează programul
                  de conformare voluntară</h5>
                <p class="card-text">ANAF a anunțat lansarea
                  unui nou program destinat contribuabililor
                  care doresc să își regleze situația fiscală.
                  Acesta va oferi asistență și consiliere
                  pentru cei care doresc să își conformeze
                  declarațiile fiscale.</p>
                <p class="text-primary"
                  style="cursor: pointer;">Citește mai
                  mult</p>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Control fiscal la marii
                  contribuabili</h5>
                <p class="card-text">ANAF a început o serie de
                  controale fiscale pentru marii
                  contribuabili, având ca scop verificarea
                  conformării cu legislația fiscală.
                  Autoritățile încurajează contribuabilii să
                  se prezinte și să colaboreze în cadrul
                  acestui proces.</p>
                <p class="text-primary"
                  style="cursor: pointer;">Citește mai
                  mult</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="row">
          <div class="col-md-6">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Modificări legislative
                  privind TVA</h5>
                <p class="card-text">Recent, au fost adoptate
                  modificări importante în legislația
                  națională privind TVA-ul. ANAF informează
                  contribuabilii despre noile reglementări și
                  impactul acestora asupra obligațiilor
                  fiscale.</p>
                <p class="text-primary"
                  style="cursor: pointer;">Citește mai
                  mult</p>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Control fiscal la marii
                  contribuabili</h5>
                <p class="card-text">ANAF a început o serie de
                  controale fiscale pentru marii
                  contribuabili, având ca scop verificarea
                  conformării cu legislația fiscală.
                  Autoritățile încurajează contribuabilii să
                  se prezinte și să colaboreze în cadrul
                  acestui proces.</p>
                <p class="text-primary"
                  style="cursor: pointer;">Citește mai
                  mult</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="row">
          <div class="col-md-6">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Campanie de informare
                  privind deducerea cheltuielilor</h5>
                <p class="card-text">ANAF derulează o campanie
                  de informare pentru contribuabili,
                  accentuând importanța cunoașterii
                  deducerilor posibile la calcularea
                  impozitelor. Cei interesați pot accesa
                  diverse resurse disponibile pe site-ul
                  oficial al ANAF.</p>
                <p class="text-primary"
                  style="cursor: pointer;">Citește mai
                  mult</p>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Termenele limită pentru
                  depunerea declarațiilor fiscale</h5>
                <p class="card-text">ANAF a început o serie de
                  controale fiscale pentru marii
                  contribuabili, având ca scop verificarea
                  conformării cu legislația fiscală.
                  Autoritățile încurajează contribuabilii să
                  se prezinte și să colaboreze în cadrul
                  acestui proces.</p>
                <p class="text-primary"
                  style="cursor: pointer;">Citește mai
                  mult</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleIndicators"
      role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleIndicators"
      role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div> -->
  <div id="carouselExample" class="carousel slide" data-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item" [class.active]="currentIndex === i" *ngFor="let slide of slides; let i = index">
        <img [src]="slide.image" class="d-block w-100" [alt]="slide.title">
        <div class="carousel-caption d-none d-md-block text-left"> <!-- Adaugă text-left pentru alinierea la stânga -->
          <h5><b>{{ slide.title }}</b></h5> <!-- Titlul este sus -->
          <p style="margin-top: 10px;">{{ slide.description }}</p> <!-- Descrierea este dedesubt -->
        </div>
      </div>
    </div>
    <a class="carousel-control-prev" (click)="prevSlide()" role="button" aria-label="Previous">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    </a>
    <a class="carousel-control-next" (click)="nextSlide()" role="button" aria-label="Next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
    </a>
  </div>
  
  
</div>