import { Component } from '@angular/core';
import { TranslationService } from '@services/translation.service';
import { orderBy } from 'lodash';

interface ActLegislativ {
  id: number;
  titlu: string;
  data: string;
  descriere: string;
}

@Component({
  selector: 'app-legislatie',
  templateUrl: './legislatie.component.html',
  styleUrls: ['./legislatie.component.scss']
})
export class LegislatieComponent {
  acts: ActLegislativ[] = [
    { id: 1, titlu: 'Actul 1', data: '01-01-2020', descriere: 'Descriere pentru Actul 1' },
    { id: 2, titlu: 'Actul 2', data: '01-05-2020', descriere: 'Descriere pentru Actul 2' },
    { id: 3, titlu: 'Actul 3', data: '01-10-2020', descriere: 'Descriere pentru Actul 3' },
    { id: 4, titlu: 'Actul 4', data: '01-01-2021', descriere: 'Descriere pentru Actul 4' },
    { id: 5, titlu: 'Actul 5', data: '01-05-2021', descriere: 'Descriere pentru Actul 5' },
    { id: 6, titlu: 'Actul 6', data: '01-10-2021', descriere: 'Descriere pentru Actul 6' },
    { id: 7, titlu: 'Actul 7', data: '01-01-2020', descriere: 'Descriere pentru Actul 1' },
    { id: 8, titlu: 'Actul 8', data: '01-05-2020', descriere: 'Descriere pentru Actul 2' },
    { id: 9, titlu: 'Actul 9', data: '01-10-2020', descriere: 'Descriere pentru Actul 3' },
    { id: 10, titlu: 'Actul 10', data: '01-01-2021', descriere: 'Descriere pentru Actul 4' },
    { id: 11, titlu: 'Actul 11', data: '01-05-2021', descriere: 'Descriere pentru Actul 5' },
    { id: 12, titlu: 'Actul 12', data: '01-10-2021', descriere: 'Descriere pentru Actul 6' },
    
    // adaugă câte acte dorești sau ia-le de la un API
  ];

  searchText: string = ''; // Câmp pentru căutare
  p: number = 1; // Pagina curentă
  itemsPerPage: number = 10; // Numărul de iteme pe pagină

  // Funcție de sortare
  sortBy: string = 'titlu'; // Criteriul de sortare implicit
  sortOrder: boolean = true; // true pentru ascendent, false pentru descendent

 constructor (public trans : TranslationService){
 }


  sort(criterion: string) {
    this.sortOrder = this.sortBy === criterion ? !this.sortOrder : true;
    this.sortBy = criterion;
    this.acts = orderBy(this.acts, [this.sortBy], [this.sortOrder ? 'asc' : 'desc']);
  }

}
