import { Component } from '@angular/core';
import { TranslationService } from '@services/translation.service';

@Component({
  selector: 'app-dosar-electronic',
  templateUrl: './dosar-electronic.component.html',
  styleUrl: './dosar-electronic.component.scss'
})
export class DosarElectronicComponent {
  constructor(public translationService: TranslationService) { }
}
