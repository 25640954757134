<div class="container mt-5 mb-6">
  <h2>Contact</h2>
  


  <form (ngSubmit)="onSubmit()">
    <div class="mb-3">
      <label for="name" class="form-label">Nume</label>
      <input type="text" id="name" [(ngModel)]="contactForm.name" name="name" class="form-control" required>
    </div>
    <div class="mb-3">
      <label for="email" class="form-label">Email</label>
      <input type="email" id="email" [(ngModel)]="contactForm.email" name="email" class="form-control" required>
    </div>
    <div class="mb-3">
      <label for="message" class="form-label">Mesaj</label>
      <textarea id="message" [(ngModel)]="contactForm.message" name="message" class="form-control" rows="4" required></textarea>
    </div>
    <button type="submit" class="btn btn-primary">Trimite</button>
  </form>

  <h3 class="mt-5">Unde ne gasiti </h3>
  <div id="map" style="height: 400px; margin-bottom: 20px;"></div>
</div>
