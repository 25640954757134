import {Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent {
    isDropdownVisible = false;

    constructor(private router :Router){}

    onClick(){
        console.log("aici")
        this.router.navigate(['/spv/inbox']); 
        this.isDropdownVisible = false;
             
    }

    toggleDropdown() {
        this.isDropdownVisible = !this.isDropdownVisible; // Schimbă starea dropdown-ului
    }
    
    // @HostListener('document:click', ['$event'])
    // handleClick(event: MouseEvent) {
    //   const target = event.target as HTMLElement;
    //     console.log(target.closest('pf-dropdown'))
    //   // Verifică dacă click-ul a avut loc pe header sau în dropdown
    //   if (this.isDropdownVisible && !target.closest('pf-dropdown')) {
    //     this.isDropdownVisible = false; // Ascunde dropdown-ul dacă s-a făcut click în afara lui
    //   }
    // }
}


// [routerLink]="['/spv/inbox']"