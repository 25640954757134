import { Component, OnInit } from '@angular/core';
import { TranslationService } from '@services/translation.service';
import { ToastrService } from 'ngx-toastr';

interface ContactForm {
  cuiOrCnp: string;
  numeComplet: string;
  denumireSocietate: string;
  email: string;
  numarTelefon: string;
  agentieFiscala: string;
  dataSiOra: string; 
}

@Component({
  selector: 'app-programare',
  templateUrl: './programare.component.html',
  styleUrls: ['./programare.component.scss']
})
export class ProgramareComponent implements OnInit {
  programare: ContactForm = {
    cuiOrCnp: '',
    numeComplet: '',
    denumireSocietate: '',
    email: '',
    numarTelefon: '',
    agentieFiscala: '',
    dataSiOra: ''
  };

  agentii: string[] = [
    'Agenția Națională de Administrare Fiscală (ANAF) - București',
    'Agenția Fiscală Regională Cluj',
    'Agenția Fiscală Regională Timișoara',
    'Agenția Fiscală Regională Iași',
    'Agenția Fiscală Regională Brașov'
  ];

  constructor(
    private toastr: ToastrService,
    public translationService: TranslationService) {}

  ngOnInit(): void {}

  onSubmit() {
    console.log('Form submitted!', this.programare);
    this.toastr.success('Programarea a fost realizată cu succes!');
    this.programare = {
      cuiOrCnp: '',
      numeComplet: '',
      denumireSocietate: '',
      email: '',
      numarTelefon: '',
      agentieFiscala: '',
      dataSiOra: ''
    };
  }
}
