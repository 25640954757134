<div class="container">
    <h2>Inbox</h2>
  
    <div class="row">
      <!-- Card pentru Mesaje -->
      <div class="col-md-12 mb-3">
        <div class="card">
          <div class="card-header d-flex justify-content-between align-items-center">
            <h5>Mesaje</h5>
            <button (click)="toggleCard('mesajeOpen')" class="btn btn-light ml-auto">
              <i class="fas" [ngClass]="cardStates.mesajeOpen ? 'fa-minus' : 'fa-plus'"></i>
            </button>
          </div>
          <div class="card-body" *ngIf="cardStates.mesajeOpen">
            <div *ngFor="let notificare of notificari.mesaje">
              <strong>{{ notificare.titlu }}</strong><br>
              <p>{{ notificare.descriere }}</p>
              <hr />
            </div>
          </div>
        </div>
      </div>
  
      <!-- Card pentru Alerte -->
      <div class="col-md-12 mb-3">
        <div class="card">
          <div class="card-header d-flex justify-content-between align-items-center">
            <h5>Alerte</h5>
            <button (click)="toggleCard('alerteOpen')" class="btn btn-light ml-auto">
              <i class="fas" [ngClass]="cardStates.alerteOpen ? 'fa-minus' : 'fa-plus'"></i>
            </button>
          </div>
          <div class="card-body" *ngIf="cardStates.alerteOpen">
            <div *ngFor="let notificare of notificari.alerte">
              <strong>{{ notificare.titlu }}</strong><br>
              <p>{{ notificare.descriere }}</p>
              <hr />
            </div>
          </div>
        </div>
      </div>
  
      <!-- Card pentru Rapoarte -->
      <div class="col-md-12 mb-3">
        <div class="card">
          <div class="card-header d-flex justify-content-between align-items-center">
            <h5>Recipise</h5>
            <button (click)="toggleCard('rapoarteOpen')" class="btn btn-light ml-auto">
              <i class="fas" [ngClass]="cardStates.rapoarteOpen ? 'fa-minus' : 'fa-plus'"></i>
            </button>
          </div>
          <div class="card-body" *ngIf="cardStates.rapoarteOpen">
            <div *ngFor="let notificare of notificari.recipise">
              <strong>{{ notificare.titlu }}</strong><br>
              <p>{{ notificare.descriere }}</p>
              <hr />
            </div>
          </div>
        </div>
      </div>
  
      <!-- Card pentru Alte -->
      <div class="col-md-12 mb-3">
        <div class="card">
          <div class="card-header d-flex justify-content-between align-items-center">
            <h5>Altele</h5>
            <button (click)="toggleCard('alteOpen')" class="btn btn-light ml-auto">
              <i class="fas" [ngClass]="cardStates.alteOpen ? 'fa-minus' : 'fa-plus'"></i>
            </button>
          </div>
          <div class="card-body" *ngIf="cardStates.alteOpen">
            <div *ngFor="let notificare of notificari.alte">
              <strong>{{ notificare.titlu }}</strong><br>
              <p>{{ notificare.descriere }}</p>
              <hr />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  